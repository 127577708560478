import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn'; // 导入中文语言包
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const timeConvert= {
  methods: {
    convertToBeijingTime(dateString) {
      const date = dayjs(dateString).tz('Asia/Shanghai');
      return date.format('MM-DD-YYYY HH:mm');
    },
  },
};
