<template>
  <div class="container">
    <form class="form-signin" @submit.prevent="login">
      <h2 class="form-signin-heading">Please sign in</h2>
      <p>
        <label for="username" class="sr-only">Username</label>
        <input v-model="formData.username" type="text" id="username" name="username" class="form-control"
          placeholder="Username" required autofocus>
      </p>
      <p>
        <label for="password" class="sr-only">Password</label>
        <input v-model="formData.password" type="password" id="password" name="password" class="form-control"
          placeholder="Password" required>
      </p>

      <div class="btn btn-lg btn-primary btn-block" @click="submit">Sign in</div>
      <div v-if="logined" class="btn btn-lg btn-primary btn-block" @click="fresh">Fresh</div>

      
    </form>

  </div>
</template>

<script>
import qs from 'qs';
export default {
  data() {
    return {
      formData: {
        username: '',
        password: '',
      },
      logined:false,

    };
  },
  methods: {
    submit() {
      this.$axios.post(
        `/api/login`,
        qs.stringify(this.formData),//这样可以藏起来
        // `/api/login?${qs.stringify(this.formData)}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
        .then(res => {
          console.log(res)
          this.$message.success(res.data);
          // this.$router.push('/blog');
          this.logined=true;
        })
        .catch(err => {
          console.log(err)
        })
    },
    fresh() {
      this.$axios.get(
        `/api/manage/freshCache`,
        qs.stringify(this.formData),//这样可以藏起来
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
        .then(res => {
          console.log(res)
          this.$message.success(res);
          this.$router.push('/blog');
          this.logined=true;
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
};
</script>

<style scoped>
.form-signin {
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}

.form-signin-heading {
  color: var(--textColor);
}

.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>