//引入Vue核心库
import Vue from 'vue'
//引入Vuex
import Vuex from 'vuex'
//应用Vuex插件
Vue.use(Vuex)

//准备actions对象——响应组件中用户的动作
const state = {
    activeName:"",
}


//创建并暴露store
export default new Vuex.Store({
	state
})