// i18n.js

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  zh: {
    welcome: 'Welcome!',
    aboutMe: 'Zeon, GIS技师, 塔学博士, NoonieBao的超级粉丝, 社会观察家, 民间科学家, 赛博垃圾制造者.',
    tbc:"🤔Hmm...这件事之后在说吧",
    Index:"Index",
    Blog:"Blog",


    // Add more key-value pairs for English
  },
  en: {
    welcome: '欢迎！',
    aboutMe:'Zeon, Technician in GIS, PhD in Towerology, huge fan of NoonieBao , a Social Observer, an Amateur Scientist, and a Cyber Garbage Maker.',
    tbc:"🤔Hmm...Let's put the matter aside for the time being",
    Index:"Index",
    Blog:"Blog",
  },
  // Add more languages as needed
};

const i18n = new VueI18n({
  locale: 'en', // set the initial locale
  fallbackLocale: 'en', // set the fallback locale
  messages,
});

export default i18n;
