<template>
    

    <div id="content-wrapper">

        
        <Loading v-if="loading"></Loading>

        <template v-else>


        <div v-if="0" id="arc-head">
            <img id="cover" :src="article.urlImgcol">
        </div>

        <template v-if="markdownText">
            <div id="mdtext" v-html="render(markdownText)"></div>
        </template>

        <template v-else>
            <div id="mdtext">
                {{ $t("tbc") }}
            </div>
        </template>


        <div id="timestamp">

            <span class="sfafa">Create: </span>
            <span>{{ releaseTime }}</span>
            <br>
            <span class="sfafa">Update: </span>
            <span>{{ updatedTime }}</span>

        </div>
    </template>


    </div> 
</template>


<script>
import markdownit from 'markdown-it'
import Prism from 'prismjs';
import Loading from './Loading.vue'

const md = markdownit({
    html: true,
    linkify: true,
    typographer: true,
    breaks: true,//防止链接过长
});

export default {
    components: {
        Loading,
    },
    data() {
        return {
            loading: true,
            markdownText: '',
            article: null,
        }
    },

    props: ['id'],
    computed: {
        releaseTime() {
            try {
                return new Date(this.article.createTime);
            } catch {
                return null
            }


        },
        updatedTime() {

            try {
                return new Date(this.article.updateTime);
            } catch {
                return null
            }

        }
    },

    mounted() {


        this.$axios.get(`/api/article/get/${this.id}`)
            .then(res => {
                console.log(res)
                this.markdownText = res.data.content
                this.article = res.data
                this.loading=false
            })
            .catch(err => {
                console.log(err)
            })

    },
    watch: {
        markdownText(newMarkdown, oldMarkdown) {
            this.$nextTick(() => {//下一次渲染后
                Prism.highlightAll();
            });

        },
    },
    methods: {

        render(mdText) {
            return md.render(mdText)
        },
    }
}
</script>


<style lang="less">
@totalWidth: 90%;


#content-wrapper {
    width: @totalWidth;
    margin: 0 auto;
    padding: 10px 20px;
    border: 1px var(--textColor) solid;
    border-radius: 1rem;
    margin-top: 20px; //不要挨着nav
}

#arc-head {
    width: @totalWidth;
    margin: 0 auto;

    >#cover {
        width: 100%;
    }

}


#mdtext {
    // overflow: hidden;

    & a {
        word-wrap: break-word;
    }

    & .md-img {
        display: block;
        margin: 0 auto;
        border-radius: 5px; //md中图片圆角
    }
}


#timestamp {
    font-size: 13px;
    margin: 10px 0;
}

pre {
    // background:none !important; 
    border-radius: 0.5rem !important;
}
</style>
