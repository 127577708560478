<template>
    <div style="padding: 5% 15%;">

        <h2>
            {{ $t("aboutMe") }}
        </h2>
        <div v-if="0" style="display: flex;justify-content: center;flex-wrap: wrap;">
            <a :href="badge.url0" target="_blank" v-for="badge in showBadgeList" style="margin: 0 2px;">

                <img
                    :src="'https://img.shields.io/badge/' + badge.text + '-' + badge.color + '?style=flat&logo=' + badge.badge + '&logoColor=white'">
            </a>
        </div>
    </div>
</template>

<script>
import { getBadgeList } from '../components/badge'
export default {
    data() {
        return {
            showBadgeList: null,
        }
    },
    mounted() {
        this.initBadge()
    },
    methods: {
        initBadge() {
            let k = ['html', 'css', 'less', 'javascri', 'java', 'openlaye', 'vue', 'spring', 'springboot', 'springsecurity', 'nginx', 'cloudflare', 'redis', 'kafka', 'docker', 'echarts', 'e',]
            let nullable = getBadgeList(k)
            console.log(k)
            console.log(nullable)
            this.showBadgeList = nullable
        }
    }
};
</script>
