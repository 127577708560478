<template>
  <Loading v-if="loading"></Loading>
   <div v-else id="article-list">
      <div class="article" v-for="article in articles">

        <router-link class="qqq" :to="'detail/' + (article.id)" append>

          <div class="mdsInfos">

            <div class="mdsCover">
              <img :src="(article.urlImgcol)" alt="" style="width: 100%;">
              <div class="tag">
                {{ article.tag }}
              </div>
            </div>

            <div class="mdsTexts">
              <span class="title">
                {{ article.title }}
              </span>

              <br>
              <span class="excerpt">
                {{ article.excerpt }}
              </span>

              <br>
              <span>
                {{ convertToBeijingTime(article.createTime) }}
              </span>
            </div>

          </div>
        </router-link>

      </div>


    </div>
    

</template>


<style lang="less" scoped>

#article-list{
  background-color: var(--bgColor);
  overflow: hidden; //防止子元素的下边距溢出导致没有颜色
}

.article {

  margin: 0 10px 10px;  
  border-bottom: 2px solid #aabbff;
  padding: 8px;
  >.qqq {

    text-decoration: none;
    color: #aabbff;
    color: var(--textColor);

    .mdsInfos {

      width: 100%;

      display: flex;
      align-items: center; //垂直居中
      position: relative;


      @sfasfa: 50%;

      >.mdsCover {
        width: 125px;
        height: 125px;
        min-width: 125px; //禁止挤兑
        min-height: 125px; //禁止挤兑
        box-sizing: border-box;
        margin-right: 20px;
        position: relative;

        >img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }

        >.tag {
          position: absolute;
          background-color: var(--tagBg);
          color: var(--tagTxt);
          left: 0;
          bottom: 0;
          border-radius: 3px;
          padding: 1px 3px;
          font-size: 12px;
          font-weight: bold;
        }
      }


      >.mdsTexts {

        overflow: hidden;
        word-wrap: break-word; //强制换行
        position: relative;



        >.title {
          font-weight: 800;
          font-size: 30px;
        }

        >.excerpt {
          font-size: 18px;
        }
      }



    }
  }

}
</style>


<script>
import Loading from './Loading.vue'

import { timeConvert } from '../mixin'


export default {
  mixins: [timeConvert,],
  name:"articlelist",
  components: {
    Loading
  },
  data() {
    return {
      loading:true,
      articles: null,
    };
  },
  methods: {
    initData() {
      this.$axios.get("/api/article/all")
        .then(res => {
          this.articles = res.data;
          this.loading=false
        })
        .catch(err => {
          console.log(err)
        })


    },


    goDetail(mdId) {
      console.log(mdId)
    }
  },
  mounted() {
    this.initData()
  },
};
</script>

