<!-- 此文件用于通用编辑页面，接受文章连接（非必须） -->
<template>
    <div>
        <!-- titie cover tag -->

        <div id="mdInfos">

            <el-upload class="avatar-uploader" id="mdCover" :action="`${$axios.defaults.baseURL}/api/img/upload`"
                :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                :with-credentials="true"
                >
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>

            <div id="mdComments">

                <el-input placeholder="title" v-model="article.title">
                </el-input>

                <el-input placeholder="excerpt" v-model="article.excerpt">
                </el-input>

                <el-input placeholder="tag" v-model="article.tag">
                </el-input>

            </div>

        </div>

        <textarea ref="markdownEditor"></textarea>
    </div>
</template>

<script>
import SimpleMDE from 'simplemde';


import '@/inline-attachment'
import '@/codemirror-4.inline-attachment'


export default {
    data() {
        return {
            article: {
                id: null,
                title: "",
                excerpt: "",
                coverId: null,
                content: "",
                tag: "",
            },
            imageUrl: ''

        }
    },
    props: {
        id: {
            required: false, //必要性
            default: null,
        },
    },
    watch: {
        article(newData, orDate) {
            this.simplemde.value(newData.content)
        },
    },
    mounted() {
        if (this.id) {
            this.$axios.get(`/api/article/get/${this.id}`)
                .then(res => {
                    const article = res.data;
                    if (article) {
                        this.article = article
                        const urlImgcol = article.urlImgcol
                        if (urlImgcol) {

                            this.imageUrl = urlImgcol
                            //Todo 
                        }
                    } else {
                        console.log("无效的文章id")
                    }

                })
                .catch(res => {
                    console.log(res)
                }
                )
        } else {
            console.log("没有传入id，可能是新增文档")
        }


        this.whatThemeNow()

        // 初始化 SimpleMDE 编辑器
        this.simplemde = new SimpleMDE({
            element: this.$refs.markdownEditor,
            // 其他 SimpleMDE 配置选项
            toolbar: [
                //     {
                //     name: "bold",
                //     action: SimpleMDE.toggleBold,
                //     className: "fa fa-bold",
                //     title: "Bold",
                // },
                // {
                //     name: "code",
                //     action: SimpleMDE.toggleCodeBlock,
                //     className: "fa fa-code",
                //     title: "Bold",
                // },
                // {
                //     name: "quote",
                //     action: SimpleMDE.toggleBlockquote,
                //     className: "fa fa-quote-left",
                //     title: "Quote",
                // },




                {
                    name: "link",
                    action: SimpleMDE.drawLink,
                    className: "fa fa-link",
                    title: "Create Link",
                },
                "|", // Separator
                {
                    name: "preview",
                    action: SimpleMDE.togglePreview,
                    className: "fa fa-eye no-disable",
                    title: "Toggle Preview",
                },

                {
                    name: "side-by-side",
                    action: SimpleMDE.toggleSideBySide,
                    className: "fa fa-columns no-disable no-mobile",
                    title: "Toggle Side by Side",
                },

                {
                    name: "fullscreen",
                    action: SimpleMDE.toggleFullScreen,
                    className: "fa fa-arrows-alt no-disable no-mobile",
                    title: "Toggle Fullscreen",
                },

                "|",
                {
                    // name: "custom",
                    action: (editor) => {
                        this.saveContent()
                    },
                    className: "fa fa-cloud-upload",
                    title: "Save",
                },
            ],

        });


        function handleurl(result) {

            console.log(result)

            return `<img class="md-img " src="${result.url}" alt="[${result.id}-${result.filename}]">`
            // return  `![${result.id}-${result.filename}](${result.url})`
        }

        var inlineAttachmentConfig = {
            uploadUrl: `${this.$axios.defaults.baseURL}/api/img/upload`,               //后端上传图片地址
            uploadFieldName: 'file',          //上传的文件名
            jsonFieldName: 'url',              //返回结果中图片地址对应的字段名称
            progressText: '![图片上传中...]()',    //上传过程中用户看到的文案
            errorText: '图片上传失败',
            withCredentials: true, // 设置为 true 以携带凭据信息
            // urlText: '![图片描述]({filename})',    //上传成功后插入编辑器中的文案，{filename} 会被替换成图片地址
            urlText: handleurl,    //上传成功后插入编辑器中的文案，{filename} 会被替换成图片地址
            // extraHeaders: {
            //     'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            // }
        };
        inlineAttachment.editors.codemirror4.attach(this.simplemde.codemirror, inlineAttachmentConfig);

    },
    beforeDestroy() {
        // 在组件销毁前销毁 SimpleMDE 实例，防止内存泄漏
        if (this.simplemde) {
            this.simplemde.toTextArea();
            this.simplemde = null;
        }
    },
    methods: {
        isDark(){
            return document.documentElement.getAttribute('data-bs-theme')=="light";
        },
        whatThemeNow(){
            const a="//cdn.jsdelivr.net/simplemde/latest/simplemde.min.css";
            const b="//cdn.rawgit.com/xcatliu/simplemde-theme-dark/master/dist/simplemde-theme-dark.min.css"
            

                if(
                    this.isDark()
                ){
                    require('simplemde/dist/simplemde.min.css')
                }else{
                    const style = document.createElement('link');
                    style.rel = 'stylesheet';
                    style.href = "//cdn.rawgit.com/xcatliu/simplemde-theme-dark/master/dist/simplemde-theme-dark.min.css";
                    document.head.appendChild(style);
                }

            
        },
        saveContent() {
            this.article.content = this.simplemde.value()
            this.$axios.post("/api/article/upload",
                this.article
            ).then(res => {
                this.article.id = res.data.id  // init if necessary
                this.$message.success('Done');
            })
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            // console.log(res)
            this.article.coverId = res.id

        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            return isLt2M;
        },
    },
};
</script>



<style lang="less" scoped>
@coverSize: 15vw;
@infoSize: ~"calc(90vw - @{coverSize})";

#mdInfos {


    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 5px;

    >#mdCover {
        width: @coverSize;
        height: @coverSize;
        border-radius: 5px;
        overflow: hidden;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;


        .avatar {
            //外面还包了一层
            width: @coverSize;
            height: @coverSize;
            object-fit: cover;
        }

         /deep/ .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: @coverSize;
            height:  @coverSize;
            line-height:  @coverSize;
            text-align: center;
         }

    }
    >#mdCover:hover {
        border-color: #409EFF;
    }
    >#mdComments {
        width: @infoSize;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        
        .el-input{
            padding: 5px 0; //防止多个输入框竟贴着
        }
    }
}
</style>